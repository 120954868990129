(function () {

    'use strict';

    // iPad and iPod detection
    var isiPad = function(){
        return (navigator.platform.indexOf('iPad') != -1);
    };

    var isiPhone = function(){
        return (
            (navigator.platform.indexOf('iPhone') != -1) ||
            (navigator.platform.indexOf('iPod') != -1)
        );
    };



    // Carousel Feature Slide
    function getAnimatedList(currentIndex, selector, action) {
        // Because the slider is set to loop the carousel the 1st and last elements
        // of the carousel are duplicated (the last element will have an index 0 and the first will have index 4)
        // We need to set the animations for the original elements not the duplicated ones
        // Otherwise the animations will not run
        if (currentIndex == 0) {
            currentIndex = 3;
        } else if (currentIndex == 4) {
            currentIndex = 1;
        }
        var mySlider = document.querySelectorAll('.my-slider .slider-item')[currentIndex];
        var toAnimate = mySlider.querySelectorAll(selector);
        for (var i = 0; i < toAnimate.length; i++) {
            if (action === 'add') {
                toAnimate[i].classList.add('fadeInUp', 'animated');
            } else if (action === 'remove') {
                toAnimate[i].classList.remove('fadeInUp', 'animated');
            } else {
                console.error('Invalid option!');
            }
        }
    }
    var tinySlider = function() {
        const slider = tns({
            container: '.my-slider',
            loop: true,
            items: 3,
            slideBy: 1,
            nav: true,
            navPosition: 'bottom',
            autoplay: false,
            speed: 400,
            autoplayButtonOutput: false,
            autoHeight: true,
            mouseDrag: true,
            lazyload: false, // this must be false otherwise the images will disappear
            controlsContainer: '#custom-control',
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 1,
                },
                768: {
                    items: 1,
                }
            },
            onInit: function(elem) {
                getAnimatedList(elem.index, '.to-animate, .to-animate-2', 'add');
            }
        });

        slider.events.on('transitionStart', function(info, eventName) {
            getAnimatedList(info.index, '.to-animate, .to-animate-2', 'remove');

        });

        slider.events.on('transitionEnd', function(info, eventName) {
            setTimeout(function() {
                getAnimatedList(info.index, '.to-animate', 'add');
            }, 700);
            setTimeout(function() {
                getAnimatedList(info.index, '.to-animate-2', 'add');
            }, 900);
        });
    };



    // animate-box
    var contentWayPoint = function() {

        $('.animate-box').waypoint( function( direction ) {

            if( direction === 'down' && !$(this).hasClass('animated') ) {

                $(this.element).addClass('fadeInUp animated');

            }

        } , { offset: '75%' } );

    };


    // Burger Menu
    var burgerMenu = function() {

        $('body').on('click', '.js-fh5co-nav-toggle', function(event){

            if ( $('#navbar').is(':visible') ) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }

            event.preventDefault();

        });

    };



    // Page Nav
    var clickMenu = function() {

        $('a:not([class="external"])').click(function(event){
            var section = $(this).data('nav-section'),
                navbar = $('#navbar');
            $('html, body').animate({
                scrollTop: $('[data-section="' + section + '"]').offset().top
            }, 500);

            if ( navbar.is(':visible')) {
                navbar.removeClass('show');
                navbar.attr('aria-expanded', 'false');
                $('.js-fh5co-nav-toggle').removeClass('active');
            }

            event.preventDefault();
            return false;
        });

    };

    // Reflect scrolling in navigation
    var navActive = function(section) {

        var $el = $('#navbar > ul');
        $el.find('li').removeClass('active');
        $el.each(function(){
            $(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
        });

    };
    var navigationSection = function() {

        var $section = $('div[data-section]');

        $section.waypoint(function(direction) {
            if (direction === 'down') {
                navActive($(this.element).data('section'));

            }
        }, {
            offset: '150px'
        });

        $section.waypoint(function(direction) {
            if (direction === 'up') {
                navActive($(this.element).data('section'));
            }
        }, {
            offset: function() { return -$(this.element).height() + 155; }
        });

    };


    // Window Scroll
    var windowScroll = function() {
        var lastScrollTop = 0;

        $(window).scroll(function(event){

            var header = $('#fh5co-header'),
                scrlTop = $(this).scrollTop();

            if ( scrlTop > 500 && scrlTop <= 2000 ) {
                header.addClass('navbar-fixed-top fh5co-animated slideInDown');
            } else if ( scrlTop <= 500) {
                if ( header.hasClass('navbar-fixed-top') ) {
                    header.addClass('navbar-fixed-top fh5co-animated slideOutUp');
                    setTimeout(function(){
                        header.removeClass('navbar-fixed-top fh5co-animated slideInDown slideOutUp');
                    }, 100 );
                }
            }

        });
    };



    // Animations

    // About Us
    var aboutAnimate = function() {

        if ( $('#about-us').length > 0 ) {
            $('#about-us .to-animate').each(function( k ) {

                var el = $(this);

                setTimeout ( function () {
                    el.addClass('fadeInUp animated');
                },  k * 200, 'easeInOutExpo' );

            });
        }

    };
    var aboutWayPoint = function() {

        if ( $('#about-us').length > 0 ) {
            $('#about-us').waypoint( function( direction ) {

                if( direction === 'down' && !$(this).hasClass('animated') ) {


                    setTimeout(aboutAnimate, 200);


                    $(this.element).addClass('animated');

                }
            } , { offset: '95%' } );
        }

    };

    // Team
    var teamAnimate = function() {

        if ( $('#team').length > 0 ) {
            $('#team .to-animate').each(function( k ) {

                var el = $(this);

                setTimeout ( function () {
                    el.addClass('fadeInUp animated');
                },  k * 200, 'easeInOutExpo' );

            });
        }

    };
    var teamWayPoint = function() {

        if ( $('#team').length > 0 ) {
            $('#team').waypoint( function( direction ) {

                if( direction === 'down' && !$(this).hasClass('animated') ) {


                    setTimeout(teamAnimate, 200);


                    $(this.element).addClass('animated');

                }
            } , { offset: '95%' } );
        }

    };

    // Services
    var servicesAnimate = function() {

        if ( $('#fh5co-our-services').length > 0 ) {
            $('#fh5co-our-services .to-animate').each(function( k ) {

                var el = $(this);

                setTimeout ( function () {
                    el.addClass('fadeInUp animated');
                },  k * 200, 'easeInOutExpo' );

            });
        }

    };
    var servicesWayPoint = function() {

        if ( $('#fh5co-our-services').length > 0 ) {
            $('#fh5co-our-services').waypoint( function( direction ) {

                if( direction === 'down' && !$(this).hasClass('animated') ) {


                    setTimeout(servicesAnimate, 200);


                    $(this.element).addClass('animated');

                }
            } , { offset: '95%' } );
        }

    };


    // Features
    var featuresAnimate = function() {

        if ( $('#fh5co-features').length > 0 ) {
            $('#fh5co-features .to-animate').each(function( k ) {

                var el = $(this);

                setTimeout ( function () {
                    el.addClass('fadeInUp animated');
                },  k * 200, 'easeInOutExpo' );

            });
        }

    };
    var featuresWayPoint = function() {

        if ( $('#fh5co-features').length > 0 ) {
            $('#fh5co-features').waypoint( function( direction ) {

                if( direction === 'down' && !$(this).hasClass('animated') ) {


                    setTimeout(function(){
                        $('.animate-features-1').addClass('animated fadeIn');
                    }, 100);
                    setTimeout(function(){
                        $('.animate-features-2').addClass('animated fadeIn');
                    }, 200);
                    setTimeout(featuresAnimate, 500);
                    setTimeout(function(){
                        $('.animate-features-3').addClass('animated fadeInUp');
                    }, 1400);


                    $(this.element).addClass('animated');

                }
            } , { offset: '95%' } );
        }

    };




    // Contact
    var contactAnimate = function() {

        if ( $('#fh5co-contact').length > 0 ) {
            $('#fh5co-contact .to-animate').each(function( k ) {

                var el = $(this);

                setTimeout ( function () {
                    el.addClass('fadeInUp animated');
                },  k * 200, 'easeInOutExpo' );

            });
        }

    };
    var contactWayPoint = function() {

        if ( $('#fh5co-contact').length > 0 ) {
            $('#fh5co-contact').waypoint( function( direction ) {

                setTimeout(function(){
                    $('.animate-contect-1').addClass('animated fadeIn');
                }, 200);
                setTimeout(function(){
                    $('.animate-contact-2').addClass('animated fadeIn');
                }, 300);
                setTimeout(contactAnimate, 700);

                $(this.element).addClass('animated');

            } , { offset: '95%' } );
        }

    };



    // Document on load.
    $(function(){

        burgerMenu();
        tinySlider();
        clickMenu();
        windowScroll();
        navigationSection();

        aboutWayPoint();
        teamWayPoint();
        servicesWayPoint();
        contactWayPoint();

    });


}());